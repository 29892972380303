.seed-search-bar {
  width: 100%;
  margin: 5px 0;
  
  input {
    width: 90%;
    margin: 0;
    padding: 2px 5px;
    border: none;
    font-size: 1.2em;
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
  }
}