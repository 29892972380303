.seed-item{
  border: 1px solid #111111;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40vw;
  margin: 5px auto;
  background-color: #EEEEEE;
  filter: drop-shadow(5px 3px);

  .album-art {
    display: flex;
    justify-content: center;
    img {
      max-width: 70px;
      border-radius: 10px;
      padding: 5px;
    }
  }

  .seed-text {
    flex-grow: 2;
    overflow: hidden;
    padding-left: 5px;
    justify-content: center;
    /* text-wrap: nowrap;
    text-overflow: ellipsis; */
    width: 100%;
    .song-main {
      font-size: 1.5rem;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
    .song-secondary {
      font-size: 1.2rem;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
  }

  .remove-seed {
    align-self: flex-end;
    height: 100%;
    width: 50px;
  }
}
