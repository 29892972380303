.settings-panel {
  width: 300px;
  top: 45px;
  padding: 10px 20px;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 5px solid rgb(0, 66, 0);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  z-index: 100;

  .setting-option {
    display: flex;
    padding: 10px 0;

    .dropdown {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 10px;
    }

    .slider {
      flex-shrink: 2;
    }
  }
  .advanced-settings {
    
  }
}



#volume-num {
  width:22px;
}