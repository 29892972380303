.seeding-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  flex-grow: 2;
  max-width: 600px;
  max-height: 90%;
  color: rgb(0, 66, 0);


  .seeds {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 2vw;
    margin-top: 0;
  }

}

.seed-wrapper{
  margin: auto;
  background-color: rgb(238, 238, 238);
  border:1px solid rgb(134, 134, 134);
  border-radius: 10px;
  max-width: 300px;
}