.dashboard-container{
  display: flex;
  flex-direction: column;
  padding: 5%;
  height: 100%;
}
.upper-dash{
  height: 100%;
  /* margin-top: 5%; */
  display: flex;
  flex-direction: row; 
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: 7px solid rgb(0, 66, 0);
  border-radius: 60px;
  background-color: white;
  overflow-y: auto;

  .dash-list{
    margin: 20px;
    width: 350px;
    min-width: 200px;
    max-height: 90%;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    white-space: nowrap;
    background-color: rgb(236, 236, 236);
    border:1px solid rgb(134, 134, 134);
  }
  .dash-list > h3{
    margin: 0;
    padding: 5px;
    position: sticky;
    top: 0;
  }
}