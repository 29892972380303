.modal-wrapper {
  background-color: #1d1d1da6;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
}


.create-playlist-modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid rgb(0,65,0);
  border-radius: 20px;
  position: absolute;
  width: 500px;
  height: 300px;
  left: 0;  
  right: 0;
  top: 40%;
  bottom: 60%;
  margin: auto;
  
  .modal-header {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-weight: bold;
    color: rgb(0,65,0);
  }

  .create-modal-content {
    display: flex;
    flex-direction: row;
    overflow: auto;
    border-top: 2px solid rgb(0,65,0);
    border-bottom: 2px solid rgb(0,65,0);
    flex-grow: 1;

    .image-container {
      display: flex;
      justify-content: center;
      width: 30%; 
      padding: 10px;
      border-right: 2px solid rgb(0,65,0);

      img {
        width: 100%;
        height: min-content;
        object-fit: contain;
        margin: auto;
        border: 2px solid rgb(0,65,0);
        filter: drop-shadow(1px 1px 3px #444444);

      }
    }

    form {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-grow: 1;
      justify-content: flex-start;
      color: rgb(0,65,0);
      font-weight: 600;

    
      .fields{
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        .field {
          display: flex;
          justify-content: flex-start;
          padding: 10px 20px;
          label {
            display: flex;
            width: 100px;
            margin-right: 10px;
            justify-content: right;
          }
          input {
            flex-grow: 1;
            border: 1px solid rgb(0,65,0);
            border-radius: 6px;
            border-color: rgb(0,65,0);
          }
          textarea {
            flex-grow: 1;
            border-color: rgb(0,65,0);
            border-width: 1px;
            border-radius: 6px;

          }

        }
      }
    }
  }

  .xout {
    position: fixed;
    top: 0;
    margin-right: 0;
  }

  .detail {
    display: flex;
    flex-direction: column;
  }
}
