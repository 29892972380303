.options {
  width: 100%;
  
  .option-row {
    margin: 1px 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      background-color: #c1ccc3;
    }
  }
}