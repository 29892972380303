.list-container{
  width: 30vw;
  background-color: white;
  border: 5px solid rgb(0, 66, 0);
  border-radius: 15px;
  overflow: auto;
}
.list-container > h3{
  margin: 0;
  padding: 5px;
  position: sticky;
  top: 0;
  background-color: rgb(0, 66, 0);
  color: white;
}
.list-body {
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  height: 94%;
  width: 100%;
}
