.add-seed-item {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;

  button {
    border-radius: 50%;
    font-size: 2em;
    width: 2em;
    height: 2em;
    border: 1px solid rgb(0, 65, 0);
    display: flex;
    align-items:center;
    justify-content: center;
    background-color: #e0e0e0;
    color: rgb(0, 65, 0);
    
    &:hover {
      cursor: pointer;
      background-color: #c1ccc3;
    }

    .input-group {
      /* height: 4vw; */

    }
  }
}