.discovery-wrapper {
  display:flex;
  flex-direction: column;
  height: 100%;
}

.discovery-container {
  height: 90%;
  width: 80%;
  margin: 5% 10%;

  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.matches-btns-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: auto;
  flex-direction: row;
  margin-top: 10px;

  .btn {
    border: 1px solid rgb(0, 172, 9);
    background-color: rgb(0, 66, 0);
    border-radius: 10px;
  }
  .btn:hover {
    cursor: pointer;
    background-color: rgb(0, 48, 0);
  }

  .export {

  }
  .copy {

  }
  .clear {

  }
}

.text-wrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: auto;
}
.export-text {
  text-align: center;
  margin: auto;
  margin-right: 5px;
  font-weight: 700;
  color: rgb(216, 216, 216)
}

.icon {
  margin-top: 25%;
}

.right {
  max-width: 400px;
  max-height: 90%;
  flex-grow: 1;

  .list-container {
    width: 100% ;
  }
  .matches {
    max-height: 90%;
  }
}

.left {
  display:flex;
  flex-direction: row;
  height: fit-content;
  max-width: 500px;
  margin-right: 30px;
  flex-grow: 1;
}
