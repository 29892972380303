.welcome {
  margin: 5%;
  /* margin-top: 8%; */
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 231, 231);
  filter: drop-shadow(10px 10px 100px darkgrey) drop-shadow(-10px -10px 100px darkgrey);

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 { 
      font-size: 3em;
    }
    button {
      width: 20%;
      height: 10%;
      font-size: 1em;
      font-weight: 700;
    }
  }
}