.banner {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100px;
  background-color: rgb(0, 66, 0);
  border-bottom-right-radius: 20px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}
.banner-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.banner-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
}
.banner-btn:hover {
  background-color:grey;
  cursor: pointer;
}