.go-wrapper {

  button {
    width: 10vw;
    min-width: 110px;
    text-wrap: nowrap;
    height: 6vh;
    border-radius: 10px;
    font-size: 1.2rem;
    border: 2px solid rgb(0,66,0);
    filter: drop-shadow(2px 2px);
  }
}